import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Sidebar from "./components/Sidebar/Sidebar.js";
import Footer from "./components/Footer/Footer.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts/cooper-hewitt/CooperHewitt-BoldItalic.otf';
import './fonts/cooper-hewitt/CooperHewitt-Book.otf';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Sidebar />
            <App />
            <Footer />
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
