import "./About.css";
import { HiOutlineMail } from "react-icons/hi"

function About() {
    return <div>
        <h1>Info</h1>
        <p>Alina Inserra ist Freie Künstlerin arbeitet vorrangig mit zeitbasierten Medien, digital und ortsbezogen. Sie ist Mitglied der Endmoräne e.V. und studierte an der Universität der Künste, Berlin sowie der Kunstakademie, Münster. Inserra war als Künstlerin in kollektiven Projekten u.a. im LWL Museum für Kunst und Kultur, Münster und den Skulptur Projekten Münster beteiligt. 2020 wurde sie zur Meisterschülerin von Aernout Mik ernannt.</p>
        <p style={{ "fontStyle": "italic" }}>Artist Statement:</p>
        <p>Ich arbeite vorrangig mit zeitbasierten Medien, digital und ortsbezogen.</p>
        <p>Meine Ideale sind auf menschliches Zusammenleben, kommunizieren und Nähe, Austausch, Begegnung ausgerichtet - ich möchte Ressourcen nachhaltig nutzen statt zu verbrauchen.</p>
        <p>Ausgehend von ästhetischen Erfahrungen in alltäglichen Beobachtung konzipiere ich begehbare Settings, voller Wahlmöglichkeiten, Entdeckungen und versteckten Details für die Besuchenden. Das Publikum wird Performer. Es werden intime Geschichten erzählt.</p>
        <p>Insbesondere Sound tritt dabei als wiederkehrendes Element auf.  Er wird den Räumen entnommen und ist durchzogen von schwebenden, poetischen Gedanken. <br />In meinen Kompostionen denke ich über Sound nach Welche Quellen für Klang gibt es? Welche Pattern erzeugen die Klangquellen selbst? Was kann ich in ihnen finden und sichtbarmachen, ohne zu viel hinzuzufügen?  Wie klingt eine Zeichnung, wenn dem Bildelementen Soundfetzen zugeordnet werden, die aus der Umgebung der Zeichnung stammen?
            Kann eine Komposition eine andere in sich verdauen? Wie hört sich das an?</p>
        <p>In kollektiven Projekten eignen wir uns gegenwärtig populäre Phänomene an - vom Startup bis zur Wrestling-Show. Wir suchen die Grenze zwischen Unterhaltung und Kunst, dabei kann sie auch verschoben werden.</p>
        <p style={{ "fontStyle": "italic" }}>Alina Inserra</p>
        <table>
            <tbody>
                <tr className="impressumMail">
                    <td><HiOutlineMail size={25} /></td>
                    <td>info(ät)alinainserra.de</td>
                </tr>
            </tbody>
        </table>
    </div >
}

export default About
