import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel'
import Container from 'react-bootstrap/Container'
import "./BlogPosts.css";

function BlogPosts() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch("https://alinainserra.com/server/api/getPosts.php")
      .then(res => res.json())
      .then(data => {
        setPosts(data);
      }).catch(err => {
        console.log(err);
      });
  }, []);

  var interval = 10000;

  function displayPosts(posts) {
    return posts.map((post, index) => {
      if (post.video !== null) {
        return (
          <div key={index} id={post.id}>
            {displayVideo(post.images)}
            <div className="post-info">
              <p className="title">{post.title}</p>
              <p className="description">{post.description ? "Foto von: " + post.description : ""}</p>
            </div>
            <br />
            <div className="post-text" dangerouslySetInnerHTML={{ __html: post.text }} />
            <div className="divider"></div>
          </div>
        );
      } else if (post.images.length > 1) {
        return (
          <div key={index} id={post.id}>
            <Carousel className="carousel">
              {displayImages(post.images, interval)}
            </Carousel>
            <div className="post-info">
              <p className="title">{post.title}</p>
              <p className="description">{post.description ? "Foto von: " + post.description : ""}</p>
            </div>
            <br />
            <div className="post-text" dangerouslySetInnerHTML={{ __html: post.text }} />
            <div className="divider"></div>
          </div>
        )
      } else {
        return (
          <div key={index} id={post.id}>
            {displayImages(post.images, interval)}
            <div className="post-info">
              <p className="title">{post.title}</p>
              <p className="description">{post.description ? "Foto von: " + post.description : ""}</p>
            </div>
            <br />
            {/* <p className="post-text">{post.text}</p> */}
            <div className="post-text" dangerouslySetInnerHTML={{ __html: post.text }} />
            <div className="divider"></div>
          </div>
        )
      }
    })
  }

  function displayVideo(video) {
    let server = "alinainserra.com/server/";
    return (
      <div className="carousel">
        <video className="carousel" controls>
          <source src={server + video} type="video/mp4" />
        </video>
      </div>
    )
  }

  function displayImages(images, interval) {
    let server = "https://alinainserra.com/server/";

    return images.map((image, index) => {
      if (images.length > 1) {
        return (
          <Carousel.Item interval={interval} key={index}>
            <img src={server + image} alt="" />
          </Carousel.Item>
        );
      } else {
        return (
          <div className="carousel" key={index}>
            <img src={server + image} alt="" />
          </div>
        )
      }

    });
  }



  return (
    <Container>
      {displayPosts(posts)}
    </Container>

  )
}



export default BlogPosts