import BlogPosts from "../../components/BlogPosts/BlogPosts"

function Home() {
    return (
        <div>
            {<BlogPosts />}
        </div>
    )
}

export default Home
