import { Routes, Route } from "react-router-dom"
import Home from "./pages/Home/Home"
import About from "./pages/About/About"
import CV from "./pages/CV/CV"
import Datenschutz from "./pages/Datenschutz/Datenschutz"
import Impressum from "./pages/Impressum/Impressum"
import './App.css'

function App() {
    return (
        <div className="app">
            <div className="line">
            </div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/cv" element={<CV />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/datenschutz" element={<Datenschutz />} />
                <Route path="*" element={<p>There's nothing here: 404!</p>} />
            </Routes>
        </div>
    );
}

export default App;
