import "./CV.css"

function CV() {
    return (
        <div>
            <h2>Vita</h2>
            <p><b>*1994, Frankfurt am Main | Diplom der Freien Kunst, Kunstakademie Münster | Residenz Labor der Künste, Kolleg für Musik und Kunst Montepulciano | Meisterschülerin Aernout Mik</b></p>
            <p><b>Zeitbasierte Medien, Fotografie, Sound Design</b></p>
            <table>
                <tbody>
                    <tr>
                        <th colSpan={2}>Education</th>
                    </tr>
                    <tr>
                        <td>2020</td>
                        <td>Meisterschülerin Aernout Mik (Diploma)</td>
                    </tr>
                    <tr>
                        <td>2020-2022</td>
                        <td>Universität der Künste, Berlin</td>
                    </tr>
                    <tr>
                        <td>2014- 2020</td>
                        <td>Academy of Fine Arts Münster</td>
                    </tr>
                    <tr>
                        <td>2013-2014</td>
                        <td>Goethe Universität Frankfurt am Main (Theater-, Film-, Medienwissenschaften)</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Grants</th>
                    </tr>
                    <tr>
                        <td>2019</td>
                        <td>Labor der Künste, Kolleg für Musik und Kunst Montepulciano</td>
                    </tr>
                    <tr>
                        <td>2017</td>
                        <td>nominiert: Studienstiftung des deutschen Volkes</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Affliations & Memberships</th>
                    </tr>
                    <tr>
                        <td>2020</td>
                        <td>Member of ENDMORÄNE Künstlerinnen aus Brandenburg und Berlin e.V.</td>
                    </tr>
                    <tr>
                        <td>2019</td>
                        <td>Member of FOAM Kollektiv, media artist* group</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Selected Exhibition History</th>
                    </tr>
                    <tr>
                        <td>2022</td>
                        <td>Blackout Altes Kino Frankfurt/Oder</td>
                    </tr>
                    <tr>
                        <td>2022</td>
                        <td>Umbrella Museum, Berlin</td>
                    </tr>
                    <tr>
                        <td>2021</td>
                        <td>Tomorrow Never Waits, Beeskow</td>
                    </tr>
                    <tr>
                        <td>2021</td>
                        <td> Open House, Speicher 2, Münster</td>
                    </tr>
                    <tr>
                        <td>2020</td>
                        <td>Examensausstellung, Kunstakademie Münster</td>
                    </tr>
                    <tr>
                        <td>2019</td>
                        <td>The Public Matters, Projekt 2077, LWL Museum, Münster</td>
                    </tr>
                    <tr>
                        <td>2019</td>
                        <td>Die dort lebenden Menschen, Kloster Wedinghausen, Arnsberg</td>
                    </tr>
                    <tr>
                        <td>2018</td>
                        <td>WRECKLINGHAUSEN, Theater im Pumpenhaus, Münster</td>
                    </tr>
                    <tr>
                        <td>2018</td>
                        <td>wolke, Eispalast, Münster</td>
                    </tr>
                    <tr>
                        <td>2018</td>
                        <td>Grugatour.heimliche Liebe, Kubig400, Essen</td>
                    </tr>
                    <tr>
                        <td>2017</td>
                        <td>Grandtour, Skultptur Projekte Münster</td>
                    </tr>
                    <tr>
                        <td>2017</td>
                        <td>Nominees, Studienstiftung des deutschen Volkes, HGB Leipzig</td>
                    </tr>
                    <tr>
                        <td>2016</td>
                        <td>Verschiebung, Emscherkunst 2016, Dortmund</td>
                    </tr>
                    <tr>
                        <td>2016</td>
                        <td>so traurig war das shooting, Kunstverein Gelsenkirchen</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Curation</th>
                    </tr>
                    <tr>
                        <td>2022</td>
                        <td>Jury: Filmprogramm „Blackout“, Altes Kino, Frankfurt/Oder</td>
                    </tr>
                    <tr>
                        <td>2018</td>
                        <td>wolke, Eispalast (offspace), Münster</td>
                    </tr>
                    <tr>
                        <td>2018</td>
                        <td>Jury: Junge Kunst, DA Kunsthaus Gravenhorst</td>
                    </tr>
                    <tr>
                        <td>2017</td>
                        <td>DEMOCRACIA: Ist eine Welt ohne Polizei möglich? Eulengasse, Frankfurt/Main</td>
                    </tr>
                    <tr>
                        <td>2016</td>
                        <td>We protect you from yourselves, Democracia, Kunstverein Friedberg e.V.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default CV
