import { NavLink as Link } from "react-router-dom"
import "./Footer.css"

const Footer = () => {
    return (
        <div className="footer">
            <Link to="/Impressum">Impressum</Link>
            <Link to="/datenschutz">Datenschutz</Link>
            <p>&copy; Alina Inserra</p>
        </div>
    )
}

export default Footer
